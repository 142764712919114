<script lang="ts">
    import type { Attachment, Post, User } from '../../interfaces/types';
    import ShakaPlayer from './ShakaPlayer.svelte';
    import ShakaPlayerApple from './ShakaPlayerApple.svelte';
    import RenderDecryptedAudio from './RenderDecryptedAudio.svelte';
    import RenderDecryptedImage from './RenderDecryptedImage.svelte';

    import Button from '$lib/components/ui/button/button.svelte';

    export let attachment: Attachment;
    export let post: Post;
    export let isGallery: boolean;
    export let isAppleDevice: string;
    export let hasVideoAttachment: boolean = false;
    export let currentUser: User | null;

    let hasSub = post.hasSub;
    $: isConverting = currentUser && post.user_id === currentUser.id && post.status === 0 && hasVideoAttachment;

    function getAttachmentType(type: string): string {
        if (type === 'avif') return 'image';
        return type.split('/')[0];
    }
</script>

<div class="media-wrapper">
    {#if getAttachmentType(attachment.type) === 'image'}
        {#if isGallery}
            <RenderDecryptedImage
                attachment="{{
                    id: attachment.id,
                    attachment_key: attachment.attachment_key,
                    path: attachment.path,
                    aspect_ratio: attachment.aspect_ratio,
                    key: attachment.key,
                }}"
                type="single"
            />
        {:else}
            {#if post.enable_public_download || (post.enable_subscriber_download && hasSub)}
                <a href="/attachment-download/{attachment.id}" class="download-button">
                    <Button aria-label="Download attachment">Download</Button>
                </a>
            {/if}
            <RenderDecryptedImage
                attachment="{{
                    id: attachment.id,
                    attachment_key: attachment.attachment_key,
                    path: attachment.path,
                    aspect_ratio: attachment.aspect_ratio,
                    key: attachment.key,
                }}"
                type="single"
            />
        {/if}
    {:else if getAttachmentType(attachment.type) === 'video'}
        <p>Attempting to render video</p>
        <div class="video-wrapper d-flex justify-content-center align-items-center">
            {#if isAppleDevice === 'Yes'}
                <ShakaPlayerApple {attachment} {post} {currentUser} {hasVideoAttachment} />
            {:else}
                <ShakaPlayer {attachment} {post} {currentUser} {hasVideoAttachment} {isConverting} />
            {/if}
        </div>
    {:else if getAttachmentType(attachment.type) === 'audio'}
        <p>Attempting to render audio</p>
        <div class="audio-wrapper h-100 w-100 d-flex justify-content-center align-items-center">
            <RenderDecryptedAudio {attachment} />
        </div>
    {:else}
        <p>Unknown attachment type: {attachment.type}</p>
    {/if}
</div>

<style>
    .media-wrapper {
        width: 100%;
        height: 100%;
        position: relative;
    }
    .video-wrapper,
    .audio-wrapper {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%; /* 16:9 aspect ratio */
    }
    :global(.video-js) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    .download-button {
        position: absolute;
        top: 10px;
        right: 10px;
        z-index: 10;
    }
</style>
